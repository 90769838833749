<template>
    <!-- Sidebar -->
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
            <template v-for="(menuData, index) in $store.getters.menuArr">
                <template v-if="menuData.is_archived == 0 && ((typeof menuData.sub_menu !== 'undefined' && menuData.sub_menu.length > 0 && (menuData.sub_menu.length != menuData.sub_menu_archive_count)) || (menuData.sub_menu.length == 0))">
                    <li v-bind:key="index" :id="menuData.slug" :class="'menuCommon '+menuData.slug">
                        <template v-if="menuData.is_view_allow == 1">
                            <template v-if="menuData.sub_menu == '' || menuData.sub_menu == null || menuData.sub_menu_view_count != 1">
                                <template v-if="menuData.url != null && menuData.url != '' && menuData.is_view_allow == 1">
                                    <!-- <template v-if="menuData.menu_title.length >= 22">
                                        <a :href="menuData.url" target="_blank">
                                            <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                            </span>
                                            {{stringTrim(menuData.menu_title,22)}}
                                        </a>
                                    </template>
                                    <template v-else> -->
                                        <div class="non-archive menu-wrapper">
                                            <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span>
                                            <a :href="menuData.url" target="_blank" class="pr-5">
                                                {{menuData.menu_title}}
                                            </a>
                                        </div>
                                    <!-- </template> -->
                                </template>
                                <template v-else-if="menuData.features.length == 1 && (menuData.features[0].name == 'Iframe URL' || menuData.features[0].id == 4) && menuData.iframe_url_arr.length > 0">
                                    <!-- Redirect to New Tab When Single Iframe Is Assigned -->
                                    <template v-if="menuData.iframe_url_arr.length == 1">
                                        <template v-for="(iframeURL, menuIframeIndex) in menuData.iframe_url_arr">
                                            <template v-if="iframeURL != null && iframeURL != ''">
                                                <!-- <template v-if="menuData.menu_title.length >= 22">
                                                    <div class="menu-wrapper" :key="menuIframeIndex">
                                                        <a :href="iframeURL" target="_blank">
                                                            <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                            </span>
                                                            {{stringTrim(menuData.menu_title,22)}}
                                                        </a>
                                                    </div>
                                                </template>
                                                <template v-else> -->
                                                    <div class="non-archive menu-wrapper" :key="menuIframeIndex">
                                                        <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span>
                                                        <a :href="iframeURL" target="_blank" class="pr-5">
                                                            {{menuData.menu_title}}
                                                        </a>
                                                    </div>
                                                <!-- </template> -->
                                            </template>
                                        </template>
                                    </template>
                                    <!-- When Multiple Iframes Are Assigned, Display A Common Page And Autoload More Than 1 Iframe -->
                                    <template v-else>
                                        <!-- <template v-if="menuData.menu_title.length >= 22">
                                            <div class="menu-wrapper">
                                                <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                    <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                    </span>{{stringTrim(menuData.menu_title,22)}}
                                                </router-link>
                                            </div>
                                        </template>
                                        <template v-else> -->
                                            <div class="non-archive menu-wrapper">
                                                <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span>
                                                <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                    {{menuData.menu_title}}
                                                </router-link>
                                            </div>
                                        <!-- </template> -->
                                    </template>
                                </template>
                                <template v-else>
                                    <!-- <template v-if="menuData.menu_title.length >= 22">
                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                            <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                            </span>{{stringTrim(menuData.menu_title,22)}}
                                        </router-link>
                                    </template>
                                    <template v-else> -->
                                        <div class="non-archive menu-wrapper">
                                            <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span>
                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                {{menuData.menu_title}}
                                            </router-link>
                                        </div>
                                    <!-- </template> -->
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="menuData.url != null && menuData.url != ''">
                                    <!-- <template v-if="menuData.menu_title.length >= 18">
                                        <div class="menu-wrapper">
                                            <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                            <a :href="menuData.url" target="_blank">
                                                <label class="tree-toggle nav-header">
                                                    <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                    </span> {{stringTrim(menuData.menu_title,18)}}
                                                </label>
                                            </a>
                                        </div>
                                    </template>
                                    <template v-else> -->
                                        <div class="non-archive menu-wrapper">
                                            <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span> 
                                            <a :href="menuData.url" target="_blank">
                                                <!-- <label class="tree-toggle nav-header"> -->
                                                    {{menuData.menu_title}}
                                                <!-- </label> -->
                                            </a>
                                            <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                        </div>
                                    <!-- </template> -->
                                </template>
                                <template v-else-if="menuData.features.length == 1 && (menuData.features[0].name == 'Iframe URL' || menuData.features[0].id == 4) && menuData.iframe_url_arr.length > 0">
                                    <!-- Redirect to New Tab When Single Iframe Is Assigned -->
                                    <template v-if="menuData.iframe_url_arr.length == 1">
                                        <template v-for="(iframeURL, menuIframeIndex) in menuData.iframe_url_arr">
                                            <template v-if="iframeURL != null && iframeURL != ''">
                                                <!-- <template v-if="menuData.menu_title.length >= 18">
                                                    <div class="menu-wrapper" :key="menuIframeIndex">
                                                        <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                                        <a :href="iframeURL" target="_blank">
                                                            <label class="tree-toggle nav-header">
                                                                <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                </span> {{stringTrim(menuData.menu_title,18)}}
                                                            </label>
                                                        </a>
                                                    </div>
                                                </template>
                                                <template v-else> -->
                                                    <div class="non-archive menu-wrapper" :key="menuIframeIndex">
                                                        <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span>
                                                        <a :href="iframeURL" target="_blank">
                                                            <!-- <label class="tree-toggle nav-header"> -->
                                                                {{menuData.menu_title}}
                                                            <!-- </label> -->
                                                        </a>
                                                        <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                                    </div>
                                                <!-- </template> -->
                                            </template>
                                        </template>
                                    </template>
                                    <!-- When Multiple Iframes Are Assigned, Display A Common Page And Autoload More Than 1 Iframe -->
                                    <template v-else>
                                        <!-- <template v-if="menuData.menu_title.length >= 18">
                                            <div class="menu-wrapper">
                                                <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                                <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                    <label class="tree-toggle nav-header">
                                                        <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                        </span> {{stringTrim(menuData.menu_title,18)}}
                                                    </label>
                                                </router-link>
                                            </div>
                                        </template>
                                        <template v-else> -->
                                            <div class="non-archive menu-wrapper">
                                                <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span> 
                                                <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                    <!-- <label class="tree-toggle nav-header"> -->
                                                        {{menuData.menu_title}}
                                                    <!-- </label> -->
                                                </router-link>
                                                <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                            </div>
                                        <!-- </template> -->
                                    </template>
                                </template>
                                <template v-else>
                                    <!-- <template v-if="menuData.menu_title.length >= 18">
                                        <div class="menu-wrapper">
                                            <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                <label class="tree-toggle nav-header">
                                                    <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                    </span> {{stringTrim(menuData.menu_title,18)}}
                                                </label>
                                            </router-link>
                                        </div>
                                    </template>
                                    <template v-else> -->
                                        <template v-if="( loginUserRoleId === 1 || loginUserRoleId === 2 || loginUsername == '14034' || loginUsername == '81038' || menuData.is_view_allow == 1) && menuData.menu_title == 'Manager\'s Section'">
                                            <div class="non-archive menu-wrapper">
                                                <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span>
                                                <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                    <!-- <label class="tree-toggle nav-header"> -->
                                                        <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                         {{menuData.menu_title}}
                                                    <!-- </label> -->
                                                </router-link>
                                                <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <template v-if="menuData.menu_title != 'Manager\'s Section'">
                                                <div class="non-archive menu-wrapper">
                                                    <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url"></span> 
                                                    <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: notArchived }}">
                                                        <!-- <label class="tree-toggle nav-header"> -->
                                                        <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                            {{menuData.menu_title}}
                                                        <!-- </label> -->
                                                    </router-link>
                                                    <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                                                </div>
                                            </template>
                                        </template>
                                    </template>
                                <!-- </template> -->
                                <ul class="nav nav-list tree">
                                    <template v-for="(subMenuData, index1) in menuData.sub_menu">
                                        <template v-if="subMenuData.is_view_allow == 1 && subMenuData.is_archived == 0">
                                            <template v-if="subMenuData.url != null && subMenuData.url != '' && subMenuData.is_view_allow == 1">
                                                <li :key="index1" :class="'subMenuLi '+subMenuData.slug">
                                                    <!-- <template v-if="subMenuData.menu_title.length >= 30">
                                                        <template v-if="subMenuData.url==training_schedular_url">
                                                            <a :href="training_schedular_token_url" target="_blank">
                                                                {{stringTrim(subMenuData.menu_title,30)}}
                                                            </a>
                                                        </template>
                                                        <template v-else-if="subMenuData.url==service_desk_url">
                                                            <a :href="service_desk_token_url" target="_blank">
                                                                {{stringTrim(subMenuData.menu_title,30)}}
                                                            </a>
                                                        </template>
                                                        <template v-else>
                                                            <a :href="subMenuData.url" target="_blank">
                                                                {{stringTrim(subMenuData.menu_title,30)}}
                                                            </a>
                                                        </template>
                                                    </template>
                                                    <template v-else> -->
                                                        <template v-if="subMenuData.url==training_schedular_url">
                                                            <a :href="training_schedular_token_url" target="_blank">
                                                                {{subMenuData.menu_title}}
                                                            </a>
                                                        </template>
                                                        <template v-else-if="subMenuData.url==service_desk_url">
                                                            <a :href="service_desk_token_url" target="_blank">
                                                                {{subMenuData.menu_title}}
                                                            </a>
                                                        </template>
                                                        <template v-else>
                                                            <a :href="subMenuData.url" target="_blank">
                                                                {{subMenuData.menu_title}}
                                                            </a>
                                                        </template>
                                                    <!-- </template> -->
                                                </li>
                                            </template>
                                            <template v-else-if="subMenuData.features.length == 1 && (subMenuData.features[0].name == 'Iframe URL' || subMenuData.features[0].id == 4) && subMenuData.iframe_url_arr.length > 0">
                                                <!-- Redirect to New Tab When Single Iframe Is Assigned -->
                                                <template v-if="subMenuData.iframe_url_arr.length == 1">
                                                    <template v-for="(submenuIframeURL, submenuIframeIndex) in subMenuData.iframe_url_arr">
                                                        <template v-if="submenuIframeURL != null && submenuIframeURL != ''">
                                                            <li :class="'subMenuLi '+subMenuData.slug">
                                                                <!-- <template v-if="subMenuData.menu_title.length >= 30">
                                                                    <a :href="submenuIframeURL" target="_blank">
                                                                        {{stringTrim(subMenuData.menu_title,30)}}
                                                                    </a>
                                                                </template>
                                                                <template v-else> -->
                                                                    <a :href="submenuIframeURL" target="_blank">
                                                                        {{subMenuData.menu_title}}
                                                                    </a>
                                                                <!-- </template> -->
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                                <!-- When Multiple Iframes Are Assigned, Display A Common Page And Autoload More Than 1 Iframe -->
                                                <template v-else>
                                                    <li :key="index1" :class="'subMenuLi '+subMenuData.slug">
                                                        <!-- <template v-if="subMenuData.menu_title.length >= 30">
                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: notArchived }}">
                                                                {{stringTrim(subMenuData.menu_title,30)}}
                                                            </router-link>
                                                        </template>
                                                        <template v-else> -->
                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: notArchived }}">
                                                                {{subMenuData.menu_title}}
                                                            </router-link>
                                                        <!-- </template> -->
                                                    </li>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <li :key="index1" :class="'subMenuLi '+subMenuData.slug">
                                                    <!-- <template v-if="subMenuData.menu_title.length >= 30">
                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: notArchived }}">
                                                            {{stringTrim(subMenuData.menu_title,30)}}
                                                        </router-link>
                                                    </template>
                                                    <template v-else> -->
                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: notArchived }}">
                                                            {{subMenuData.menu_title}}
                                                        </router-link>
                                                    <!-- </template> -->
                                                </li>
                                            </template>
                                        </template>
                                    </template>
                                </ul>
                            </template>
                        </template>
                    </li>
                    <!-- <li>
                        <label class="tree-toggle nav-header"> <span class="fa-stack fa-lg pull-left"> <i class="fa fa-map-marker fa-stack-1x "></i></span> Sales Programs <i class="fa fa-angle-down" aria-hidden="true"></i></label>
                        <ul class="nav nav-list tree">
                            <li>Standard Operating Procedures</li>
                            <li>Marketing Materials</li>
                            <li>Warranty</li>
                            <li>Certification</li>
                            <li>Sales Meetings</li>
                            <li><a href="/colorchart">Color Chart</a></li>
                            <li><a href="#">Buttons</a></li>
                        </ul>
                    </li> -->
                </template>
            </template>
            <li class="menuCommon forms">
                <label class="menu-wrapper">
                    <!-- <span class="spm-nav-menu icon-procedures"> -->
                    <span class="spm-nav-menu sidebar-menu-icon icon-forms"></span>
                    <div class="flex-fill"> Forms </div>
                    <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                </label>
                <ul class="nav nav-list tree">

                    <li id="TechTimeForm">
                        <!-- <router-link v-bind:to="{name: 'ListIframeTechTime'}"> -->
                        <a :href="techTimeIframeURL" target="_blank">
                            Technician Time Edit Request
                        </a>
                        <!-- </router-link> -->
                    </li>

                    <!-- Technician Time Edit Request (Spanish) -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="TechTimeSpanishForm">
                            <router-link v-bind:to="{name: 'ListSpanishTechTime'}">
                                Technician Time Edit Request (Spanish)
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="TechTimeSpanishForm">
                            <router-link v-bind:to="{name: 'AddUpdateSpanishTechTime'}">
                                Technician Time Edit Request (Spanish)
                            </router-link>
                        </li>
                    </template> -->

                    <li id="SupportStaffForm">
                        <!-- <router-link v-bind:to="{name: 'ListIframeSupportStaff'}"> -->
                        <a :href="supportStaffIframeURL" target="_blank">
                            Dayforce Time Edit Request
                        </a>
                        <!-- </router-link> -->
                    </li>

                    <!-- Dayforce Time Edit Request (Spanish) -->
                    <li id="SupportStaffSpanishForm">
                        <router-link :to="loginUserRoleId === 1 || loginUserRoleId === 2
                                        ? { name: 'ListSpanishSupportStaff' }
                                        : { name: 'AddUpdateSpanishSupportStaff' }
                                        ">
                                    Dayforce Time Edit Request (Spanish)
                        </router-link>
                    </li>

                    <!-- Abandoned Vehicle Request Form -->
                    <li id="AbandonedVehicleRequestForm">
                        <!-- <router-link v-bind:to="{name: 'ListAbandonedVehicleRequest'}"> -->
                            <a :href="abandonedVehicleIframeURL" target="_blank">
                                Abandoned Vehicle Request Form
                            </a>
                        <!-- </router-link> -->
                    </li>

                    <!-- CPC -->
                    <template v-if="permissionsArray.includes('access_cpc_request_form') || loginUserRoleId == 2">
                        <li id="ListCPC">
                            <router-link v-bind:to="{name: 'ListCPC'}">
                                CPC
                            </router-link>
                        </li>
                    </template>

                    <!-- Dayforce Time Edit Request -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="SupportStaffForm">
                            <router-link v-bind:to="{name: 'ListSupportStaff'}">
                                Dayforce Time Edit Request
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="SupportStaffForm">
                            <router-link v-bind:to="{name: 'AddUpdateSupportStaff'}">
                                Dayforce Time Edit Request
                            </router-link>
                        </li>
                    </template> -->
                    
                    <!-- Dispute/Chargeback Request Form -->
                    <li id="DisputeChargebackRequestForm">
                        <!-- <router-link v-bind:to="{name: 'ListDisputeChargebackRequest'}"> -->
                            <a :href="disputeChargeBackIframeURL" target="_blank">
                                Dispute/Chargeback Request Form
                            </a>
                        <!-- </router-link> -->
                    </li>

                    <!-- EXWU / Tablet Request Form -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="TableRequestForm">
                            <router-link v-bind:to="{name: 'ListTableRequest'}">
                                EXWU / Tablet Request Form
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="TableRequestForm">
                            <router-link v-bind:to="{name: 'AddUpdateTableReq'}">
                                EXWU / Tablet Request Form
                            </router-link>
                        </li>
                    </template> -->

                    <!-- Form Email Manager -->
                    <template v-if="permissionsArray.includes('access_form_email_manager')">
                        <li id="ListFormEmailManager">
                            <router-link v-bind:to="{name: 'ListFormEmailManager'}">
                                Form Email Manager
                            </router-link>
                        </li>
                    </template>

                    <!-- Labor Review -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="LaborReview">
                            <router-link v-bind:to="{name: 'ListLaborReview'}">
                                Labor Review
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="LaborReview">
                            <router-link v-bind:to="{name: 'AddUpdateLaborReview'}">
                                Labor Review
                            </router-link>
                        </li>
                    </template> -->


                    <!-- Office Supplies Order -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="OfficeSuppliesForm">
                            <router-link v-bind:to="{name: 'ListOfficeSupplier'}">
                                Office Supplies Order
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="OfficeSuppliesForm">
                            <router-link v-bind:to="{name: 'AddUpdateOfficeSupplier'}">
                                Office Supplies Order
                            </router-link>
                        </li>
                    </template> -->

                    <!-- PPE Order Form -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="PPEOrderSupploes">
                            <router-link v-bind:to="{name: 'ListPPEOrderSupplies'}">
                                PPE Order Form
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="PPEOrderSupploes">
                            <router-link v-bind:to="{name: 'AddUpdatePPEOrderSupplies'}">
                                PPE Order Form
                            </router-link>
                        </li>
                    </template> -->

                    <!-- RO Reversal Request -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="ROReversalRequest">
                            <router-link v-bind:to="{name: 'ListROReversalRequest'}">
                                RO Reversal Request
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="ROReversalRequest">
                            <router-link v-bind:to="{name: 'AddUpdateROReversalRequest'}">
                                RO Reversal Request
                            </router-link>
                        </li>
                    </template> -->
                    <li id="ROReversalRequest">
                        <!-- <router-link v-bind:to="{name: 'ListIframeROReversalRequest'}"> -->
                        <a :href="roReversalIframeURL" target="_blank">
                            RO Reversal Request
                        </a>
                        <!-- </router-link> -->
                    </li>

                    <!-- Supplies Order Form -->
                    <li id="suppliedOrderForm">
                        <a :href="suppliesOrderIframeURL" target="_blank">
                            Supplies Order Form
                        </a>
                    </li>

                    <!-- Technician Time Edit Request -->
                    <!-- <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="TechTimeForm">
                            <router-link v-bind:to="{name: 'ListTechTime'}">
                                Technician Time Edit Request
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="TechTimeForm">
                            <router-link v-bind:to="{name: 'AddUpdateTechTime'}">
                                Technician Time Edit Request
                            </router-link>
                        </li>
                    </template> -->

                    <!-- Tech Labor Review Request Form -->
                    <li id="techLaborReviewRequestForm">
                        <a :href="techLaborReviewIframeURL" target="_blank">
                            Tech Labor Review Request Form
                        </a>
                    </li>

                    <!-- Tech Support Request Form -->
                    <li id="ServiceTechSupportRequestForm">
                        <router-link v-bind:to="{name: 'ListServiceTechSupportRequest'}">
                            Tech Support Request Form
                        </router-link>
                    </li>

                    <!-- Training RO Request -->
                    <li id="TrainingRORequest">
                        <a :href="trainignROIframeURL" target="_blank">
                            Training RO Request
                        </a>
                    </li>

                    <!-- CX Specialist-Training Request -->
                    <li id="cxSpecialistTrainingRequestForm">
                        <a :href="supportTrainingReqIframeURL" target="_blank">
                            CX Specialist-Training Request
                        </a>
                    </li>

                    <!-- Travel Request Form -->
                    <li id="TrainingTravelRequestForm">
                        <!-- <router-link v-bind:to="{name: 'ListTrainingTravel'}"> -->
                        <a :href="travelReqIframeURL" target="_blank">
                            <!-- Training Travel Request Form -->
                            Travel Request Form
                        </a>
                        <!-- </router-link> -->
                    </li>

                    <!-- Uniform Request -->
                    <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                        <li id="TechnicianUniForm">
                            <router-link v-bind:to="{name: 'ListTechnicianUniform'}">
                                Uniform Request
                            </router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li id="TechnicianUniForm">
                            <router-link v-bind:to="{name: 'AddUpdateTechnicianUniform'}">
                                Uniform Request
                            </router-link>
                        </li>
                    </template>

                    <!-- Vendor -->
                    <!-- <template v-if="permissionsArray.includes('access_vendor_request_form')">
                        <li id="ListVendor">
                            <router-link v-bind:to="{name: 'ListVendor'}">
                                Vendor
                            </router-link>
                        </li>
                    </template> -->

                    <!--
                        Date: 7th Feb, 2024
                        Reason: Based on request from Tina Boatright, added the VVG Sort Location Updates Iframe URL
                        vvgSortLocUpdatesIframeURL : 'https://app.smartsheet.com/b/form/99be2f71f94947d1b418982329b387c5',
                    -->
                    <!-- VVG Sort Location Updates -->
                    <li id="vvgSortLocUpdatesForm">
                        <a :href="vvgSortLocUpdatesIframeURL" target="_blank">
                            VVG Sort Location Updates
                        </a>
                    </li>

                    <li id="aftermarketWarrantyClaimRequestForm">
                        <a :href="aftermarketWarrantyClaimRequestIframeURL" target="_blank">
                            Aftermarket Warranty Claim Request Form
                        </a>
                    </li>
                </ul>
            </li>

            <template v-if="permissionsArray.includes('manage_contact_extension_location_and_department')">
                <li class="menuCommon ext-dir">
                    <label class="menu-wrapper">
                        <span class="spm-nav-menu sidebar-menu-icon icon-archives"></span>
                        <div class="flex-fill"> Extension Directory </div>
                        <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                    </label>
                    <ul class="nav nav-list tree">

                        <li id="ListCExtCompany">
                            <router-link v-bind:to="{name: 'ListCExtCompany'}">
                                Company Master
                            </router-link>
                        </li>

                        <li id="ListCExtLocation">
                            <router-link v-bind:to="{name: 'ListCExtLocation'}">
                                Location Master
                            </router-link>
                        </li>

                        <li id="ListCExtDepartment">
                            <router-link v-bind:to="{name: 'ListCExtDepartment'}">
                                Department Master
                            </router-link>
                        </li>

                        <li id="ListCExtLocationContact">
                            <router-link v-bind:to="{name: 'ListCExtLocationContact'}">
                                Location Extension Master
                            </router-link>
                        </li>

                        <li id="ListCExtDepartmentContact">
                            <router-link v-bind:to="{name: 'ListCExtDepartmentContact'}">
                                Department Extension Master
                            </router-link>
                        </li>

                        <li id="ListCExtUserContact">
                            <router-link v-bind:to="{name: 'ListCExtUserContact'}">
                                User Extension Master
                            </router-link>
                        </li>
                    </ul>
                </li>
            </template>

            <template v-if="permissionsArray.includes('access_contact_extension_directory')">
                <li>
                    <router-link v-bind:to="{ name:'ViewLocationDepartments', params:{slug : extension_slug}}">
                        <label class="menu-wrapper">
                            <span class="spm-nav-menu sidebar-menu-icon icon-vtc-contacts"></span>
                            Contact Extension Directory
                        </label>
                    </router-link>
                </li>
            </template>
            
            <template v-if="loginUserRoleId == 1 || loginUserRoleId == 2">
                <li class="menuCommon archived">
                    <label class="menu-wrapper">
                        <span class="spm-nav-menu sidebar-menu-icon icon-archives"></span>
                        <div class="flex-fill">Archived </div> 
                        <i class="fa fa-angle-down menu-arrow archive-arrow" aria-hidden="true"></i>
                    </label>
                    <ul class="nav nav-list tree">
                        <li id="SupportStaffForm">
                            <router-link v-bind:to="{name: 'ListSupportStaff'}">
                                Dayforce Time Edit Request
                            </router-link>
                        </li>
                        <li id="OfficeSuppliesForm">
                            <router-link v-bind:to="{name: 'ListOfficeSupplier'}">
                                Office Supplies Order
                            </router-link>
                        </li>
                        <li id="PPEOrderSupploes">
                            <router-link v-bind:to="{name: 'ListPPEOrderSupplies'}">
                                PPE Order Form
                            </router-link>
                        </li>
                        <li id="ROReversalRequest">
                            <router-link v-bind:to="{name: 'ListROReversalRequest'}">
                                RO Reversal Request
                            </router-link>
                        </li>
                        <li id="TechTimeForm">
                            <router-link v-bind:to="{name: 'ListTechTime'}">
                                Technician Time Edit Request
                            </router-link>
                        </li>

                        <li id="TechTimeSpanishForm">
                            <router-link v-bind:to="{name: 'ListSpanishTechTime'}">
                                Technician Time Edit Request (Spanish)
                            </router-link>
                        </li>

                        <li id="TrainingRequestForm">
                            <router-link v-bind:to="{name: 'ListTrainingRequest'}">
                                Training Request
                            </router-link>
                        </li>

                        <template v-if="permissionsArray.includes('access_vendor_request_form')">
                            <li id="ListVendor">
                                <router-link v-bind:to="{name: 'ListVendor'}">
                                    Vendor
                                </router-link>
                            </li>
                        </template>

                        <template v-for="(menuData, index) in $store.getters.menuArr">
                            <template v-if="menuData.is_archived == 1 || (typeof menuData.sub_menu !== 'undefined' && menuData.sub_menu.length > 0 && menuData.sub_menu.length == menuData.sub_menu_archive_count)">
                                <li v-bind:key="index" :id="menuData.slug" :class="'archived-menu pl-0 menuCommon '+menuData.slug">
                                    <template v-if="menuData.is_view_allow == 1">
                                        <template v-if="menuData.sub_menu == '' || menuData.sub_menu == null || menuData.sub_menu_view_count != 1">
                                            <template v-if="menuData.url != null && menuData.url != '' && menuData.is_view_allow == 1">
                                                <!-- <template v-if="menuData.menu_title.length >= 18">
                                                    <a :href="menuData.url" target="_blank"> -->
                                                        <!-- <span :class="'spm-nav-menu ' + menuData.icon_url">     -->
                                                        <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                        </span> -->
                                                        <!-- {{stringTrim(menuData.menu_title,18)}}
                                                    </a>
                                                </template>
                                                <template v-else> -->
                                                    <div class="menu-wrapper">
                                                        <a :href="menuData.url" target="_blank">
                                                            <!-- <span :class="'spm-nav-menu ' + menuData.icon_url">     -->
                                                            <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                            </span> -->
                                                            {{menuData.menu_title}}
                                                        </a>
                                                    </div>
                                                <!-- </template> -->
                                            </template>
                                            <template v-else-if="menuData.features.length == 1 && (menuData.features[0].name == 'Iframe URL' || menuData.features[0].id == 4) && menuData.iframe_url_arr.length > 0">
                                                <!-- Redirect to New Tab When Single Iframe Is Assigned -->
                                                <template v-if="menuData.iframe_url_arr.length == 1">
                                                    <template v-for="(iframeURL, menuIframeIndex) in menuData.iframe_url_arr">
                                                        <template v-if="iframeURL != null && iframeURL != ''">
                                                            <!-- <template v-if="menuData.menu_title.length >= 18">
                                                                <a :href="iframeURL" target="_blank" :key="menuIframeIndex"> -->
                                                                    <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                    </span> -->
                                                                    <!-- {{stringTrim(menuData.menu_title,18)}}
                                                                </a>
                                                            </template>
                                                            <template v-else> -->
                                                                <div class="menu-wrapper">
                                                                    <a :href="iframeURL" target="_blank" :key="menuIframeIndex">
                                                                        <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                        </span> -->
                                                                        {{menuData.menu_title}}
                                                                    </a>
                                                                </div>
                                                            <!-- </template> -->
                                                        </template>
                                                    </template>
                                                </template>
                                                <!-- When Multiple Iframes Are Assigned, Display A Common Page And Autoload More Than 1 Iframe -->
                                                <template v-else>
                                                    <!-- <template v-if="menuData.menu_title.length >= 18">
                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}"> -->
                                                            <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                            </span> -->
                                                            <!-- {{stringTrim(menuData.menu_title,18)}}
                                                        </router-link>
                                                    </template>
                                                    <template v-else> -->
                                                        <div class="menu-wrapper">
                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}">
                                                                <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                </span> -->
                                                                {{menuData.menu_title}}
                                                            </router-link>
                                                        </div>
                                                    <!-- </template> -->
                                                </template>
                                            </template>
                                            <template v-else>
                                                <!-- <template v-if="menuData.menu_title.length >= 18">
                                                    <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}"> -->
                                                        <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                        <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                        </span> -->
                                                        <!-- {{stringTrim(menuData.menu_title,18)}}
                                                    </router-link>
                                                </template>
                                                <template v-else> -->
                                                    <div class="menu-wrapper">
                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}">
                                                            <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                            <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                            </span> -->
                                                            {{menuData.menu_title}}
                                                        </router-link>
                                                    </div>
                                                <!-- </template> -->
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="menuData.url != null && menuData.url != ''">
                                                <!-- <template v-if="menuData.menu_title.length >= 18">
                                                    <div class="menu-wrapper">
                                                        <i class="fa fa-angle-down archive-menu-arrow" aria-hidden="true"></i>
                                                        <a :href="menuData.url" target="_blank">
                                                            <label class="tree-toggle nav-header"> -->
                                                                <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                                <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                </span> --> 
                                                                <!-- {{stringTrim(menuData.menu_title,18)}}
                                                            </label>
                                                        </a>
                                                    </div>
                                                </template>
                                                <template v-else> -->
                                                    <div class="menu-wrapper p-0">
                                                        <a :href="menuData.url" target="_blank">
                                                            <!-- <label class="tree-toggle nav-header"> -->
                                                                <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                                <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                </span> --> 
                                                                {{menuData.menu_title}}
                                                            <!-- </label> -->
                                                        </a>
                                                        <i class="fa fa-angle-down archive-menu-arrow mr-0" aria-hidden="true"></i>
                                                    </div>
                                                <!-- </template> -->
                                            </template>
                                            <template v-else-if="menuData.features.length == 1 && (menuData.features[0].name == 'Iframe URL' || menuData.features[0].id == 4) && menuData.iframe_url_arr.length > 0">
                                                <!-- Redirect to New Tab When Single Iframe Is Assigned -->
                                                <template v-if="menuData.iframe_url_arr.length == 1">
                                                    <template v-for="(iframeURL, menuIframeIndex) in menuData.iframe_url_arr">
                                                        <template v-if="iframeURL != null && iframeURL != ''">
                                                            <!-- <template v-if="menuData.menu_title.length >= 18">
                                                                <div class="menu-wrapper" :key="menuIframeIndex">
                                                                    <i class="fa fa-angle-down archive-menu-arrow" aria-hidden="true"></i>
                                                                    <a :href="iframeURL" target="_blank">
                                                                        <label class="tree-toggle nav-header"> -->
                                                                            <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                            </span> --> 
                                                                            <!-- {{stringTrim(menuData.menu_title,18)}}
                                                                        </label>
                                                                    </a>
                                                                </div>
                                                            </template>
                                                            <template v-else> -->
                                                                <div class="menu-wrapper p-0" :key="menuIframeIndex">
                                                                    <a :href="iframeURL" target="_blank">
                                                                        <!-- <label class="tree-toggle nav-header"> -->
                                                                            <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                            </span>  -->
                                                                            {{menuData.menu_title}}
                                                                        <!-- </label> -->
                                                                    </a>
                                                                    <i class="fa fa-angle-down archive-menu-arrow mr-0" aria-hidden="true"></i>
                                                                </div>
                                                            <!-- </template> -->
                                                        </template>
                                                    </template>
                                                </template>
                                                <!-- When Multiple Iframes Are Assigned, Display A Common Page And Autoload More Than 1 Iframe -->
                                                <template v-else>
                                                    <!-- <template v-if="menuData.menu_title.length >= 18">
                                                        <div class="menu-wrapper">
                                                            <i class="fa fa-angle-down archive-menu-arrow" aria-hidden="true"></i>
                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}">
                                                                <label class="tree-toggle nav-header"> -->
                                                                    <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                    </span> --> 
                                                                    <!-- {{stringTrim(menuData.menu_title,18)}}
                                                                </label>
                                                            </router-link>
                                                        </div>
                                                    </template>
                                                    <template v-else> -->
                                                        <div class="menu-wrapper p-0">
                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}">
                                                                <!-- <label class="tree-toggle nav-header"> -->
                                                                    <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                    </span> --> 
                                                                    {{menuData.menu_title}}
                                                                <!-- </label> -->
                                                            </router-link>
                                                            <i class="fa fa-angle-down archive-menu-arrow mr-0" aria-hidden="true"></i>
                                                        </div>
                                                    <!-- </template> -->
                                                </template>
                                            </template>
                                            <template v-else>
                                                <!-- <template v-if="menuData.menu_title.length >= 18">
                                                    <div class="menu-wrapper">
                                                        <i class="fa fa-angle-down archive-menu-arrow" aria-hidden="true"></i>
                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}">
                                                            <label class="tree-toggle nav-header"> -->
                                                                <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                                <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                </span>  -->
                                                                <!-- {{stringTrim(menuData.menu_title,18)}}
                                                            </label>
                                                        </router-link>
                                                    </div>
                                                </template>
                                                <template v-else> -->
                                                    <template v-if="( loginUserRoleId === 1 || loginUserRoleId === 2 || loginUsername == '14034' || loginUsername == '81038' || menuData.is_view_allow == 1) && menuData.menu_title == 'Manager\'s Section'">
                                                        <div class="menu-wrapper p-0">
                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}">
                                                                <!-- <label class="tree-toggle nav-header"> -->
                                                                    <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                                    <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                    </span>  -->
                                                                    {{menuData.menu_title}}
                                                                <!-- </label> -->
                                                            </router-link>
                                                            <i class="fa fa-angle-down archive-menu-arrow mr-0" aria-hidden="true"></i>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="menuData.menu_title != 'Manager\'s Section'">
                                                            <div class="menu-wrapper p-0">
                                                                <router-link v-bind:to="{name: 'CommonPage', params:{type:menuData.slug}, query: { isArchived: archived }}">
                                                                    <!-- <label class="tree-toggle nav-header"> -->
                                                                    <!-- <span :class="'spm-nav-menu ' + menuData.icon_url"> -->
                                                                        <!-- <span :class="'spm-nav-menu sidebar-menu-icon ' + menuData.icon_url">
                                                                        </span>  -->
                                                                        {{menuData.menu_title}}
                                                                    <!-- </label> -->
                                                                </router-link>
                                                                <i class="fa fa-angle-down archive-menu-arrow mr-0" aria-hidden="true"></i>
                                                            </div>
                                                        </template>
                                                    </template>
                                                <!-- </template> -->
                                            </template>
                                            <ul class="nav nav-list tree archive-tree">
                                                <template v-for="(subMenuData, index1) in menuData.sub_menu">
                                                    <template v-if="subMenuData.is_view_allow == 1">
                                                        <template v-if="subMenuData.url != null && subMenuData.url != '' && subMenuData.is_view_allow == 1">
                                                            <li :key="index1" :class="'subMenuLi '+subMenuData.slug">
                                                                <!-- <template v-if="subMenuData.menu_title.length >= 30">
                                                                    <template v-if="subMenuData.url==training_schedular_url">
                                                                        <a :href="training_schedular_token_url" target="_blank">
                                                                            {{stringTrim(subMenuData.menu_title,30)}}
                                                                        </a>
                                                                    </template>
                                                                    <template v-else-if="subMenuData.url==service_desk_url">
                                                                        <a :href="service_desk_token_url" target="_blank">
                                                                            {{stringTrim(subMenuData.menu_title,30)}}
                                                                        </a>
                                                                    </template>
                                                                    <template v-else>
                                                                        <a :href="subMenuData.url" target="_blank">
                                                                            {{stringTrim(subMenuData.menu_title,30)}}
                                                                        </a>
                                                                    </template>
                                                                </template>
                                                                <template v-else> -->
                                                                    <template v-if="subMenuData.url==training_schedular_url">
                                                                        <a :href="training_schedular_token_url" target="_blank">
                                                                            {{subMenuData.menu_title}}
                                                                        </a>
                                                                    </template>
                                                                    <template v-else-if="subMenuData.url==service_desk_url">
                                                                        <a :href="service_desk_token_url" target="_blank">
                                                                            {{subMenuData.menu_title}}
                                                                        </a>
                                                                    </template>
                                                                    <template v-else>
                                                                        <a :href="subMenuData.url" target="_blank">
                                                                            {{subMenuData.menu_title}}
                                                                        </a>
                                                                    </template>
                                                                <!-- </template> -->
                                                            </li>
                                                        </template>
                                                        <template v-else-if="subMenuData.features.length == 1 && (subMenuData.features[0].name == 'Iframe URL' || subMenuData.features[0].id == 4) && subMenuData.iframe_url_arr.length > 0">
                                                            <!-- Redirect to New Tab When Single Iframe Is Assigned -->
                                                            <template v-if="subMenuData.iframe_url_arr.length == 1">
                                                                <template v-for="(submenuIframeURL, submenuIframeIndex) in subMenuData.iframe_url_arr">
                                                                    <template v-if="submenuIframeURL != null && submenuIframeURL != ''">
                                                                        <li :class="'subMenuLi '+subMenuData.slug">
                                                                            <!-- <template v-if="subMenuData.menu_title.length >= 18">
                                                                                <a :href="submenuIframeURL" target="_blank">
                                                                                    {{stringTrim(subMenuData.menu_title,18)}}
                                                                                </a>
                                                                            </template>
                                                                            <template v-else> -->
                                                                                <a :href="submenuIframeURL" target="_blank">
                                                                                    {{subMenuData.menu_title}}
                                                                                </a>
                                                                            <!-- </template> -->
                                                                        </li>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            <!-- When Multiple Iframes Are Assigned, Display A Common Page And Autoload More Than 1 Iframe -->
                                                            <template v-else>
                                                                <li :key="index1" :class="'subMenuLi '+subMenuData.slug">
                                                                    <!-- <template v-if="subMenuData.menu_title.length >= 18">
                                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                            {{stringTrim(subMenuData.menu_title,18)}}
                                                                        </router-link>
                                                                    </template>
                                                                    <template v-else> -->
                                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                            {{subMenuData.menu_title}}
                                                                        </router-link>
                                                                    <!-- </template> -->
                                                                </li>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <li :key="index1" :class="'subMenuLi '+subMenuData.slug">
                                                                <!-- <template v-if="subMenuData.menu_title.length >= 18">
                                                                    <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                        {{stringTrim(subMenuData.menu_title,18)}}
                                                                    </router-link>
                                                                </template>
                                                                <template v-else> -->
                                                                    <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                        {{subMenuData.menu_title}}
                                                                    </router-link>
                                                                <!-- </template> -->
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                            </ul>
                                        </template>
                                    </template>
                                </li>
                            </template>
                        </template>
                        
                        <template v-for="(menuData, index) in $store.getters.menuArr">
                            <template  v-if="menuData.is_archived == 0">
                                <template v-if="menuData.is_view_allow == 1">
                                    <template v-if="menuData.sub_menu != '' || menuData.sub_menu != null || menuData.sub_menu_view_count == 1">
                                        <!-- <ul class="nav nav-list tree"> -->
                                            <div class="archived-nav archived-nav-list">
                                                <template v-for="(subMenuData, index1) in menuData.sub_menu">
                                                    <template v-if="subMenuData.is_archived == 1 && subMenuData.is_view_allow == 1">
                                                        <template v-if="subMenuData.url != null && subMenuData.url != '' && subMenuData.is_view_allow == 1">
                                                            <!-- <li :key="index1" :class="'subMenuLi '+subMenuData.slug"> -->
                                                                <div :key="index1" :class="'archived-nav-listitem subMenuLi '+subMenuData.slug">
                                                                    <!-- <template v-if="subMenuData.menu_title.length >= 30">
                                                                        <template v-if="subMenuData.url==training_schedular_url">
                                                                            <a :href="training_schedular_token_url" target="_blank">
                                                                                {{stringTrim(subMenuData.menu_title,30)}}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else-if="subMenuData.url==service_desk_url">
                                                                            <a :href="service_desk_token_url" target="_blank">
                                                                                {{stringTrim(subMenuData.menu_title,30)}}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <a :href="subMenuData.url" target="_blank">
                                                                                {{stringTrim(subMenuData.menu_title,30)}}
                                                                            </a>
                                                                        </template>
                                                                    </template>
                                                                    <template v-else> -->
                                                                        <template v-if="subMenuData.url==training_schedular_url">
                                                                            <a :href="training_schedular_token_url" target="_blank">
                                                                                {{subMenuData.menu_title}}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else-if="subMenuData.url==service_desk_url">
                                                                            <a :href="service_desk_token_url" target="_blank">
                                                                                {{subMenuData.menu_title}}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <a :href="subMenuData.url" target="_blank">
                                                                                {{subMenuData.menu_title}}
                                                                            </a>
                                                                        </template>
                                                                    <!-- </template> -->
                                                                </div>
                                                            <!-- </li> -->
                                                        </template>
                                                        <template v-else-if="subMenuData.features.length == 1 && (subMenuData.features[0].name == 'Iframe URL' || subMenuData.features[0].id == 4) && subMenuData.iframe_url_arr.length > 0">
                                                            <!-- Redirect to New Tab When Single Iframe Is Assigned -->
                                                            <template v-if="subMenuData.iframe_url_arr.length == 1">
                                                                <template v-for="(submenuIframeURL, submenuIframeIndex) in subMenuData.iframe_url_arr">
                                                                    <template v-if="submenuIframeURL != null && submenuIframeURL != ''">
                                                                        <!-- <li :class="'subMenuLi '+subMenuData.slug"> -->
                                                                            <div :class="'archived-nav-listitem subMenuLi '+subMenuData.slug">
                                                                                <!-- <template v-if="subMenuData.menu_title.length >= 18">
                                                                                    <a :href="submenuIframeURL" target="_blank">
                                                                                        {{stringTrim(subMenuData.menu_title,18)}}
                                                                                    </a>
                                                                                </template>
                                                                                <template v-else> -->
                                                                                    <a :href="submenuIframeURL" target="_blank">
                                                                                        {{subMenuData.menu_title}}
                                                                                    </a>
                                                                                <!-- </template> -->
                                                                            </div>
                                                                        <!-- </li> -->
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            <!-- When Multiple Iframes Are Assigned, Display A Common Page And Autoload More Than 1 Iframe -->
                                                            <template v-else>
                                                                <!-- <li :key="index1" :class="'subMenuLi '+subMenuData.slug"> -->
                                                                    <div :key="index1" :class="'archived-nav-listitem subMenuLi '+subMenuData.slug">
                                                                        <!-- <template v-if="subMenuData.menu_title.length >= 18">
                                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                                {{stringTrim(subMenuData.menu_title,18)}}
                                                                            </router-link>
                                                                        </template>
                                                                        <template v-else> -->
                                                                            <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                                {{subMenuData.menu_title}}
                                                                            </router-link>
                                                                        <!-- </template> -->
                                                                    </div>
                                                                <!-- </li> -->
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <!-- <li :key="index1" :class="'subMenuLi '+subMenuData.slug"> -->
                                                                <div :key="index1" :class="'archived-nav-listitem subMenuLi '+subMenuData.slug">
                                                                    <!-- <template v-if="subMenuData.menu_title.length >= 30">
                                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                            {{stringTrim(subMenuData.menu_title,30)}}
                                                                        </router-link>
                                                                    </template>
                                                                    <template v-else> -->
                                                                        <router-link v-bind:to="{name: 'CommonPage', params:{type:subMenuData.slug}, query: { isArchived: archived }}">
                                                                            {{subMenuData.menu_title}}
                                                                        </router-link>
                                                                    <!-- </template> -->
                                                                </div>
                                                            <!-- </li> -->
                                                        </template>
                                                    </template>
                                                </template>
                                            </div>
                                        <!-- </ul> -->
                                    </template>
                                </template>
                            </template>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
<!-- Sidebar -->
</template>

<script>
    import Vue from 'vue';
    import {HTTP} from '../../http-common.js';
    import EventBus from '.././event-bus';
    var self;

    import {commonMixins} from '../../mixins/commonMixins';

    export default {
        name: 'Center',
        mixins: [commonMixins],
        data() {
            return {
                menuArr: [],
                loginUserRoleId: 1,
                training_schedular_url:'',
                training_schedular_token_url:'',
                service_desk_url:'',
                service_desk_token_url:'',
                loginUsername: 9999999,
                permissionsArray: ['access_vendor_request_form', 'access_form_email_manager'],
                extension_slug: '',
                roReversalIframeURL : 'https://app.smartsheet.com/b/form/4be44178076d464db2124424aafdfef7',
                travelReqIframeURL : 'https://app.smartsheet.com/b/form/f050ab1b66d747fb854293e9f81d5fa9',
                disputeChargeBackIframeURL : 'https://app.smartsheet.com/b/form/45fb4151b58c40609c9be841356863e3',
                /**
                    Date: 6th Feb, 2024
                    Reason: 
                    Based on request from Tina Boatright, updating the Dayforce Time Edit Request Iframe URL
                    supportStaffIframeURL : 'https://app.smartsheet.com/b/form/09d0bfc248bb4c13a393f029fde0db35',
                */ 
                supportStaffIframeURL : 'https://app.smartsheet.com/b/form/09762bf511f74e7da17e80e9b03c4b40',
                techTimeIframeURL : 'https://app.smartsheet.com/b/form/1c6d4672d40840009b1ec19a859ecbc4',
                trainignROIframeURL : 'https://app.smartsheet.com/b/form/12ca4a19755c40cb8b89f2ae4224593b',
                archived : 1,
                notArchived : 0,
                techLaborReviewIframeURL : 'https://app.smartsheet.com/b/form/eb4815ef812e418ab759f95203ab1127',
                suppliesOrderIframeURL : 'https://app.smartsheet.com/b/form/d377ddaf786e4b7d81b869ff1b3f103f',
                abandonedVehicleIframeURL : "https://app.smartsheet.com/b/form/4d1ac7d134934f5caccf0d32fff317f6",
                aftermarketWarrantyClaimRequestIframeURL : 'https://app.smartsheet.com/b/form/e164c9dd67d64989bdbcd9bc77d3683d',
                supportTrainingReqIframeURL : 'https://app.smartsheet.com/b/form/16fc67b04ea34dc89139c37db6e31279',
                vvgSortLocUpdatesIframeURL : 'https://app.smartsheet.com/b/form/99be2f71f94947d1b418982329b387c5'
            }

        },
        beforeMount: function () {
        },
        mounted: function () {
            self = this;
            let tkn = localStorage.getItem("tkn");
            let training_schedular = process.env.VUE_APP_TRAINING_SCHEDULAR_URL;
            self.training_schedular_url = training_schedular;
            self.training_schedular_token_url = training_schedular + "?token=" + tkn;
            let service_desk= process.env.VUE_APP_SERVICE_HELPDESK_URL;
            self.service_desk_url = service_desk;
            self.service_desk_token_url = service_desk + "?token=" + tkn;
            $("#menu-toggle").click(function (e) {
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled");
            });
            $("#menu-toggle-2").click(function (e) {
                //console.log("here");
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled-2");
                $('#menu ul').hide();
            });

            /* $('.tree-toggle').click(function () {
                $(this).parent().children('ul.tree').toggle(200);
            });
            $('.menu-arrow').click(function () {
                $(this).parent().children('ul.tree').toggle(200);
            }); */

            var userObj = localStorage.getItem('user_obj');
            if (userObj && userObj != null) {
                var userJson = JSON.parse(userObj);
                // self.loginUserRoleId = userJson['role'].system_id;
                self.loginUsername = userJson['username'];
                // console.log(self.loginUsername);
                // self.$store.dispatch('loadMenu');
                self.getUserPermissions();
            }
        },
        methods: {
        }
    };
    EventBus.$on('user-role', function (roleId) {
        if (typeof (self) != 'undefined') {
            self.loginUserRoleId = roleId;
        }
    });
    EventBus.$on('reload-menu', function (menuArr) {
        //console.log('Event Called');
        if (typeof (self) != 'undefined') {
            self.$nextTick(() => {
                self.firstLoadMenu();
            });
        }
    });
    EventBus.$on("permissions", function(isLogin, permissionArray) {
        if (typeof self != "undefined") {
            self.permissionsArray = [];
            if(permissionArray && permissionArray.length > 0) {
                for (var i = 0; i < permissionArray.length; i++) {
                    self.permissionsArray.push(permissionArray[i].name);
                }
            }
        }
    });
    EventBus.$on('contact', function (isLogin, company) {
        if (typeof (self) != "undefined") {
            self.isShowLoggedinfields = isLogin;
            self.extension_slug = company;
        }
    });
</script>
<style lang="css" scoped>
ul ul {
    list-style-type: disc;
    padding-left: 20px;
}
ul ul ul {
    list-style-type: circle;
    padding-left:20px !important;
}
.archived-nav-listitem {
    display: list-item;
}
</style>