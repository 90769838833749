<template>
    <!-- Sidebar -->
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
            <template v-for="(menu, index) in $store.getters.cntExtMenuData">
                <li :key="index" :id="'company_' + menu.id" :class="'company menuCommon '+menu.id">
                    <template v-if='menu.locations.length > 0'>
                        <div :title="menu.name" class="menu-wrapper" v-on:click.prevent="toggleMenu(menu)"> 
                            <div v-if="menu.logo != ''" class="cnt-ext-sidebar-first-letter">
                                <img :src="menu.logo">
                            </div>
                            <div v-else class="cnt-ext-sidebar-first-letter">
                                {{getFirstChar(menu.name)}}
                            </div>
                            <a href="javascript:;"> 
                                <span class="ext-menu-title">{{ menu.name }}</span>
                            </a>
                            <i class="fa fa-angle-down menu-arrow" aria-hidden="true"></i>
                        </div>

                        <ul class="cext-nav nav nav-list tree">
                            <template v-for="(subMenuData, index1) in menu.locations">
                                <li :key="index1" :id="'location' + subMenuData.id" :class="'subMenuLi '+subMenuData.name">
                                    <template v-if="subMenuData.department_count != 0">
                                        <router-link :title="menu.name" v-bind:to="{name: 'ViewContactList',params:{company:menu.name,location:subMenuData.name}}">
                                            {{ subMenuData.name }}
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        <span class="ext-menu-title">
                                            {{ subMenuData.name }} 
                                            <small class="color-c3202b ml-1">(No Data)</small>
                                        </span> 
                                    </template>
                                </li>
                            </template>
                        </ul>

                    </template>
                    <template v-else>
                        <div class="menu-wrapper">
                            <div v-if="menu.logo != ''" class="cnt-ext-sidebar-first-letter">
                                <img :src="menu.logo">
                            </div>
                            <div class="cnt-ext-sidebar-first-letter" v-else>
                                {{getFirstChar(menu.name)}}
                            </div>
                            <span class="ext-menu-title">
                                {{ menu.name }} 
                                <small class="color-c3202b ml-1">(No Data)</small>
                            </span> 
                        </div>
                    </template>
                </li>
            </template>
        </ul>
    </div>
<!-- /#wrapper -->   
</template>

<script>
    import Vue from "vue";
    import EventBus from '.././event-bus';
    import {HTTP} from '../../http-common.js';
    import {commonMixins} from '../../mixins/commonMixins';

    var self;
    export default {
        name: 'ExtensionSidebar',
        mixins: [commonMixins],
        data() {
            return {
                cntExtMenuDataArr : [],
                isShowLoggedinfields: true,
                permissionsArray: [],
                roleId: 0,
                roleName: 0,
                system_year: '',
                openedMenu: {
                    id: 0
                },
                loginUserRoleId: 1,
                routerName: 'VTC'
            }
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            var self=this;
            EventBus.$emit("sidebarType", 'extension');
            var userObj = localStorage.getItem('user_obj');
            if (userObj && userObj != null) {
                var userJson = JSON.parse(userObj);
                // self.loginUserRoleId = userJson['role'].system_id;
                self.loginUsername = userJson['username'];
                self.$store.dispatch('loadCntExtMenuData');
            }
            self.getUserPermissions();

            var contact_detail_open = localStorage.getItem("contact_detail_open");
            contact_detail_open = (contact_detail_open === null || typeof (contact_detail_open) === "undefined" || contact_detail_open == '') ? false : contact_detail_open;
            if (!contact_detail_open) {
                window.localStorage.setItem("contact_detail_open", true);
            }

            /* $("#menu-toggle").on("click", function (e) {
                $(".app-sidebar-right").toggleClass("toggled");
                self.setWidthToContactContainer(550);
            }); */

            $("#menu-toggle-2").on("click", function (e) {
                $(".app-sidebar-right.toggled").toggleClass("toggled toggled-2");
                $('#menu ul').hide();
                self.setWidthToContactContainer(550);
            });

            $('.tree-toggle').click(function () {
                $(this).parent().children('ul.tree').toggle(200);
            });

            // $('.tree-toggle').click(function () {
            //     /*$('ul.tree').hide();*/
            //     $(this).parent().children('ul.tree').toggle(200);
            //     if ($(this).children().eq(1).attr('class').indexOf('fa-angle-down') != -1) {
            //         $(this).children().eq(1).addClass('fa-angle-up');
            //         $(this).children().eq(1).removeClass('fa-angle-down');
            //     } else {
            //         $(this).children().eq(1).removeClass('fa-angle-up');
            //         $(this).children().eq(1).addClass('fa-angle-down');
            //     }
            // });
            /* setTimeout(function () {
                $('.nav-list li a').click(function () {
                    $(this).parents('.nav-list').css('display','block');
                });
                if($('.router-link-active').length > 0){
                    $(this).parents('.nav-list').css('display','block');
                }
            }, 2000); */


            setTimeout(function () {
                if(self.cntExtMenuDataArr.length > 0) {
                    if(typeof(self.$route.params.slug)  != 'undefined' && self.$route.params.slug != null && self.$route.params.slug != '') {
                        if(self.$route.params.slug == 'VTC') {
                            self.openedMenu = self.cntExtMenuDataArr[0]; // Get Selected Company Object
                        } else {
                            let activeCntExtMenu = self.cntExtMenuDataArr.find(o => o.name === self.$route.params.slug);

                            if(typeof(activeCntExtMenu)  != 'undefined' && activeCntExtMenu != null && activeCntExtMenu != '') {
                                self.openedMenu = self.cntExtMenuDataArr[activeCntExtMenu.id - 1]; // Get Selected Company Object
                            }
                        }

                        /* Using JQuery Enable Selected Company Sidebar Block -- START*/
                        if ($("#company_" + self.openedMenu.id).children().eq(0).children().eq(2).attr('class').indexOf('fa-angle-down') != -1) {
                            $("#company_" + self.openedMenu.id).children().eq(0).children().eq(2).addClass('fa-angle-up');
                            $("#company_" + self.openedMenu.id).children().eq(0).children().eq(2).removeClass('fa-angle-down');
                            $("#company_" + self.openedMenu.id).children().eq(0).addClass('active');

                            /* $("#company_" + self.openedMenu.id).children().eq(1).slideDown(200);
                            $("#company_" + self.openedMenu.id).children().eq(1).addClass('show'); */

                        }  /* else {
                            if($("#company_" + self.openedMenu.id + " ul.tree").css('display') == 'none'){
                                // $("#company_" + self.openedMenu.id).children().eq(1).slideDown(200);
                                $("#company_" + self.openedMenu.id).children().eq(1).addClass('show');
                            } else{
                                // $("#company_" + self.openedMenu.id).children().eq(1).slideUp(200);
                                $("#company_" + self.openedMenu.id).children().eq(1).removeClass('show');
                            }
                        } */
                        // $("#company_" + self.openedMenu.id).children().eq(1).slideDown(200);
                        $("#company_" + self.openedMenu.id).children().eq(1).addClass('show');
                        /* Using JQuery Enable Selected Company Sidebar Block -- END*/
                    }
                }
            }, 1000);
        },
        methods: {
            toggleMenu(menu) {
                self.routerName = menu.name;
                if (self.openedMenu.id != menu.id) {
                    self.$router.push('/directory/extensions/' + menu.name);

                    if ($("#company_" + menu.id).children().eq(0).children().eq(2).attr('class').indexOf('fa-angle-down') != -1) {
                        $("#company_" + menu.id).children().eq(0).children().eq(2).addClass('fa-angle-up');
                        $("#company_" + menu.id).children().eq(0).children().eq(2).removeClass('fa-angle-down');
                        $("#company_" + menu.id).children().eq(0).addClass('active');

                        $.each(self.cntExtMenuDataArr, function (i) {
                            if (menu.id != self.cntExtMenuDataArr[i].id) {
                                $("#company_" + self.cntExtMenuDataArr[i].id).children().eq(0).removeClass('active');
                                $("#company_" + self.cntExtMenuDataArr[i].id).children().eq(0).children().eq(2).removeClass('fa-angle-up');
                                $("#company_" + self.cntExtMenuDataArr[i].id).children().eq(0).children().eq(2).addClass('fa-angle-down');
                                $("#company_" + self.cntExtMenuDataArr[i].id).children().eq(1).removeClass('show');
                            }
                        });

                        $("#company_" + menu.id).children().eq(1).addClass('show');
                    }
                } else {
                    $("#company_" + menu.id).children().eq(1).addClass('show');
                }
                self.openedMenu = menu;
            },

            initMenu: function () {
                $('#menu ul').hide();
                $('#menu ul').children('.current').parent().show();
                //$('#menu ul:first').show();
                $('#menu li a').click(
                        function () {
                            var checkElement = $(this).next();
                            if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
                                return false;
                            }
                            if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
                                $('#menu ul:visible').slideUp('normal');
                                checkElement.slideDown('normal');
                                return false;
                            }
                        }
                );
            }
        }
    }
    
    EventBus.$on('user-role', function (roleId) {
        if (typeof (self) != 'undefined') {
            self.loginUserRoleId = roleId;
        }
    });

    EventBus.$on("permissions", function(isLogin, permissionArray) {
        if (typeof self != "undefined") {
            self.permissionsArray = [];
            if(permissionArray && permissionArray.length > 0) {
                for (var i = 0; i < permissionArray.length; i++) {
                    self.permissionsArray.push(permissionArray[i].name);
                }
            }
        }
    });

    EventBus.$on('cnt-ext-directory-menu', function(cntExtMenuDataArr) {
        if (typeof self != "undefined") {
            // self.cntExtMenuDataArr = [];
            self.cntExtMenuDataArr = cntExtMenuDataArr;
        }
    }); 
</script>
<style scoped>
ul ul {
    list-style-type: disc;
    padding-left: 20px;
}
.company .menu-wrapper.active {
    background-color:  #098de1;
}
.company .menu-wrapper.active,
.sidebar-nav li .active a {
    color:white !important;
}
</style>
